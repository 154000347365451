import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import LogoS from '../../assets/images/kiran-photo.JPG'
import './index.scss'

const Home = () => {
  return (
    <>
      <div className="container home-page">
        <div className="photo">
          <img src={LogoS} alt="Kiran Sharma" className="circular-photo" />
        </div>
        <div className="text-zone">
          <h1>
            {' '}
            Hi, <br /> I am Kiran Sharma
          </h1>
          <h2>
            Software Engineer / Mobile Developer / Traveller
          </h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Home
