import { useState } from 'react'
import {
  faJava,
  faCss3,
  faReact,
  faAndroid,
  faGithub,
  faGit,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass] = useState('text-animate')

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            A software Engineer with a passion for coding and problem-solving
            and expertise in designing, developing, testing, implementing, and
            integrating software solutions, offering 3+ years of experience.
            With a solid foundation in software development and a strong desire
            to continue learning and growing in the field as an expert in
            various programming languages and tools and currently working as a
            mobile developer using both cross-platform and native development
            techniques using Flutter and Kotlin.
          </p>
          <p align="LEFT">
            I am also passionate about sharing industry-level knowledge with my
            students of various colleges, such that they will grab knowledge
            about recent tech knowledge such that they will updated on current
            trends.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAndroid} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faReact} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faJava} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faGit} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGithub} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About